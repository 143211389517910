import {whenSeen} from '/github/app/assets/modules/github/behaviors/dynamic-elements'
whenSeen('launch-code', () => import('/github/app/components/account_verifications/launch-code-element.ts'))
whenSeen('metric-selection', () => import('/github/app/components/advisories/metric-selection-element.ts'))
whenSeen('severity-calculator', () => import('/github/app/components/advisories/severity-calculator-element.ts'))
whenSeen('command-palette-page', () => import('/github/app/components/command_palette/command-palette-page-element.ts'))
whenSeen('command-palette-page-stack', () => import('/github/app/components/command_palette/command-palette-page-stack-element.ts'))
whenSeen('readme-toc', () => import('/github/app/components/files/readme-toc-element.ts'))
whenSeen('delayed-loading', () => import('/github/app/components/github/delayed-loading-element.ts'))
whenSeen('feature-callout', () => import('/github/app/components/github/feature-callout-element.ts'))
whenSeen('codespaces-policy-form', () => import('/github/app/components/organizations/settings/codespaces-policy-form-element.ts'))
whenSeen('action-list', () => import('/github/app/components/primer/action_list/action-list-element.ts'))
whenSeen('memex-project-picker', () => import('/github/app/components/repositories/memex/memex-project-picker-element.ts'))
whenSeen('project-picker', () => import('/github/app/components/sidebar/project-picker-element.ts'))